.sidebar {
	h3 {
		text-transform: none;
		text-transform: uppercase;
		font-weight: bold;
		color: #003f54;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	ul {
		@extend .list-unstyled;
		li {
			line-height: 24px;
			margin: 0;
			padding: 0;
			&:before {
				  content: "";
				  display: inline-block;
				  width: 0;
				  height: 0;
				  border-top: 4px solid transparent;
				  border-bottom: 4px solid transparent;
				  border-left: 4px solid #333;
				  margin-left: 5px;
				  margin-right: 5px;
				  position: relative;
			}
			&:hover {
				&:before {
					border-left-color: $brand-primary;
				}
			}
		}
		a {
			color: #666666;
			border-bottom: 1px solid #EDEDDE;
			padding: 8px 0;
			&:hover {
				color: $brand-primary;
				text-decoration: none;
			}
		}
	}
}
.push-bottom {
	margin-bottom: 35px;
}