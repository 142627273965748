// Header Structure
.top-bar {
	background: #003f54;
	padding: 0;
	border-bottom: 1px solid #e9e9e9;
	color: white;
	ul,
	li {
		padding: 0;
		margin: 0;
	}
	li.last-child {
		border-left: 1px solid #999999;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.slider-overlay {
	background: rgba(0,63,84,0.9);
	position: absolute;
    top: -510px;
    height: 480px;
    right: 0;
    z-index: 100;
    width: 100%;
    padding: 20px;
    margin: 0;
	h2 {
		color: white;
		font-weight: 500;
		display: block;
		font-size: 18px;
		text-align: center;
		border-top: 2px solid white;
		border-bottom: 2px solid white;
		padding: 15px 0;
		margin-bottom: 25px;
	}
	p {
		color: white;
		font-size: 100%;
	}
	a.btn {
		display: block;
		padding: 15px 0;
		font-size: 20px;
	}
}
.navbar-brand {
    margin-left: -40px !important;
    img {
    	@include breakpoint(xs) {
    		max-width: 100%;
    	}
    }
    @include breakpoint(xs) {
    	margin-left: 0 !important;
    }
}
.postrel {
	position: relative;
}
.top-bar {
	font-size: 16px;
	font-weight: bold;
	border-color: #9CCF46 !important;
	line-height: 46px;
}
.top-bar a {
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	padding: 0 10px;
	display: inline-block;
	border-left: 1px solid #999999;
	&:hover {
		background: $brand-primary;
	}
}
.navbar {
	margin-top: 20px;
	@include breakpoint(xs) { 
		margin-bottom: 0;
	}
}
.tagline {
	text-align: right;
	padding-top: 5px;
	font-style: italic;
  	font-size: 32px;
  	@include breakpoint(xs) {
  		text-align: center !important;
  	}
}
.navbar-brand {
	max-width: 100%;
	height: auto;
	padding: 0 0 0 10px;
	@include breakpoint(xs) { 
		padding-bottom: 25px;
	}
	h1 {
		padding: 0;
		margin: 0;
	}
	img {
		width: 400px;
	}
}
.hero {
	.nopad {
		padding-left: 0;
		padding-right: 0;
	}
	.hero-tiles {
		padding: 0;
		margin: 0 0 35px 0;
		height: 550px;
		li {
			height: 33.33%;
			margin: 0;
			border: 0;
			padding-left: 25px;
			border-radius: 0;
			color: white;
			vertical-align: middle;
			font-size: 24px;
			line-height: 170px;
			position: relative;
			text-transform: uppercase;
			&:first-child {
				background: #366675;
			}
			&:nth-child(2) {
				background: darken(#366675,5%);
			}
			&:last-child {
				background: darken(#366675,10%);
			}
			i {
				margin-right: 15px;
			}
		}
	}
}
// Navigation
nav[role=main-nav] {
	margin-top: $size-sm;
	@include breakpoint(xs) { 
		margin-top: 0;
	}
}
#menu-primary-navigation {
	a {
		font-style: normal;
		line-height: 20px;
		margin-left: 3px;
		margin-right: 3px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		padding: 10px 13px;
		&:hover {
		  	background: $brand-primary;
		  	color: white;
		  	text-decoration: none;
		}
	}
	.active a {
		@extend a:hover;
	}
}
.nav-secondary {
	@extend .list-inline;
}
.navbar-collapse {
	background: transparent;
	padding-right: 0;
	@include breakpoint(xs) { 
		background: $brand-primary;
		a {
			color: white;
			font-weight: bold;
		}
		li {
			border-bottom: dashed 1px $gray-lighter;
			&:last-child {
				border: none;
			}
		}
	}
}
// Mobile Nav
.navbar-nav {
	margin-top: 25px;
	@include breakpoint(xs) { 
		margin: 0 -15px;
	}
}
.navbar-toggle {
	background: $brand-primary;
	.icon-bar {
		background: white;
	}
}
// Dropdown menu
.nav .open>a {
	background: $brand-primary;
	color: white;
}
.navbar-right .dropdown-menu {
	right: 3px;
}
#menu-primary-navigation {
	.dropdown-menu {
		background: $brand-primary;
		li a {
			color: white;
			font-weight: normal;
			text-transform: none;
			font-size: 12px;
			padding: 5px 12px;
			@include breakpoint(xs) { 
				padding: 8px 0 8px 45px;
			}
		}
		li {
			border: none;
		}
	}
}