// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}
.aligncenter {
  display: block;
  margin: ($line-height-computed / 2) auto;
}
img.aligncenter {
  display: block !important;
  text-align: center;
  margin: 0 auto;
}
.noPad {
  padding: 0 !important;
}
.img-thumbnail {
  background: transparent !important;
}
.alignleft,
.alignright {
  margin-bottom: ($line-height-computed / 2);
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($line-height-computed / 2);
  }
  .alignright {
    float: right;
    margin-left: ($line-height-computed / 2);
  }
}
.main img.size-medium.alignleft {
  margin: 0 15px 15px 0 !important;
}
// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  @extend .thumbnail.caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
blockquote {
  display: inline-block;
  font-weight: bold;
  border-color: transparent;
  color: #0093c4;
  p {
    color: #0093c4;
  }
}
.list-group ul {
  margin-left: 0;
  padding-left: 10px;
  list-style: disc;
}
.list-group ul > li {
  border: 0;
  display: list-item;
  list-style: disc;
  padding: 5px 0;
  margin-left: 25px;
}
img.noBot {
  margin-bottom: 0;
}