body {
	padding: 0;
	margin: 0;
	color: #131313;
}
a {
	transition: 0.4s all;
	&:hover,
	&:visited {
		transition: 0.4s all;
		text-decoration: none;
	}
}
p {
	color: #131313;
  	line-height: 24px;
  	margin: 0 0 20px;
  	@include breakpoint(sm) {
  		text-align: justify !important;
  	}
}
.main {
	min-height: 700px;
	h3 {
		color: #003f54;
		margin-bottom: 33px;
		margin-top: 25px;
	}
	img.size-medium,
	img.size-thumbnail {
		@extend .img-responsive;
		@extend .img-thumbnail;
	}
	img {
		@include breakpoint(sm) {
			margin-bottom: 15px !important;
		}
	}
}
img.responsive {
	width: 100%;
	height: auto;
}
main {
	background: url('../../assets/images/header-bg-img.jpg') top left no-repeat;
	background-size: 500px;
}
.home main {
	background: url('../../assets/images/header-bg-img.jpg') center left no-repeat;
	background-size: 500px;
}
.page-template-template-contact-php main {
	background: url('../../assets/images/header-bg-img-contact.jpg') bottom left no-repeat;
	background-size: 500px;
	@include breakpoint(sm) {
		background: url('../../assets/images/header-bg-img-contact.jpg') center left no-repeat;
	}
}
h2, h3, h4, h5, h6 {
	text-transform: uppercase;
	color: #003f54;
}
.hero .hero-tiles {
	margin: 0 !important;
}
.home-intro {
	padding: 5px 0 !important;
}
.home-intro p {
	max-width: none !important;
	display: block !important;
}
.home-intro img {
	margin-right: 15px;
}
.content-info {
	padding: 30px 0 0 0;
}
img.thumbnail,
img.size-medium,
img.size-thumbnail,
img.size-small {
	border: 0 !important;
}
.row {
	@include breakpoint(sm) {
		padding: 15px 0;
	}
}
.btn {
	@include breakpoint(sm) {
		display: block !important;
	}
}
.content-info p {
	@include breakpoint(sm) {
		text-align: left !important;
	}
}
.copyright-info,
.copyright-info ul,
.copyright-info li {
	@include breakpoint(sm) {
		text-align: center;
	}
}
.main img {
	@include breakpoint(sm) {
		display: block !important;
		margin: 0 auto !important;
	}
}