// Pre Footer
section.featured.footer {
  margin: $size-lg 0 0 0;
}
section.call-to-action {
  position: relative;
  clear: both;
}
section.featured {
  background: #F0F0ED;
  border-top: 5px solid #ECECE8;
  padding: 50px 0;
  &:before {
  	content: "";
	display: block;
	position: absolute;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #FFF;
	height: 15px;
	width: 15px;
	top: -5px;
	left: 50%;
	margin-left: -7px;
  }
}

// Real Footer
.content-info {
	background: #00607F;
	color: #cccccc;
	padding: 45px 0 25px 0;
	margin-top: $size-lg;
	font-size: 14px;
	a,
	h1, h2, h3, h4, h5, h6 {
		color: white;
		text-decoration: none;
	}
	p {
		color: #cccccc;
	}
	h1, h2, h3, h4, h5, h6 {
		padding-bottom: $size-md;
		@include breakpoint(xs) { 
			padding: $size-md 0 $size-sm 0;
			border-bottom: 1px dashed lighten(#777777,10%);
		}
	}
	a:hover {
		text-decoration: underline;
	}
}
.footer-contact {
	i {
		color: white;
		margin-right: 5px;
	}
	strong {
		color: white;
		font-weight: 600;
		margin-right: 10px;
	}
}
.footer-subscribe {
	label {
		visibility: hidden;
	}
	input {
		background: #dadada;
	}
	input[type=submit] {
		background: $brand-primary;
		border: none;
		outline: none;
		&:hover,
		&:active {
			outline: none;
		}
	}
	.gform_confirmation_message {
		font-weight: bold;
		color: white;
	}
}
.copyright-info {
	background: #003040;
	color: #ffffff;
	padding: $size-md 0 $size-sm 0;
	a {
		color: white;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.footer-social-icons {
	padding: 0;
	margin: 0;
	li {
		display: inline-block;
		padding: 0;
		margin: 0 5px 0 0;
		list-style: none;
	}
	i {
		font-size: 42px;
	}
	a {
		display: inline-block;
	}
	.facebook i {color: #ffffff;}
	.twitter i {color: #00aced;}
	.googleplus i {color: #dd4b39;}
	.linkedin i {color: #007bb6;}
	.youtube i {color: #bb0000;}
}