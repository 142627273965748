// Global Pages
.page-header {
	padding: 0 0 15px 0;
	margin: 0;
	border: none;
	h2 {
		margin-bottom: 32px;
	}
	.lead p {
		font-size: 22px;
  		line-height: 1.5em;
    	margin-bottom: 20px;
    	font-weight: 300;
	}
}
.main h2,
.main h3 {
  @include breakpoint(xs) {
    dispaly: block;
    text-align: center;
  }
}
.page-top {
	background-color: darken($brand-primary,22%);
	border-bottom: 5px solid #CCC;
  border-top: 5px solid $brand-green;
  margin-bottom: 35px;
  min-height: 50px;
  padding: 20px 0;
  position: relative;
  text-align: left;
  color: #cccccc;
  #breadcrumbs {
  	background: none;
		margin: -3px 0 0;
		padding: 0;
    @include breakpoint(xs) {
      text-align: center;
    }
    a {
      color: $brand-green;
    }
  }
  h1 {
  	border-bottom: 5px solid $brand-primary;
		color: #FFF;
		display: inline-block;
		font-weight: 200;
		margin: 0 0 -25px;
		min-height: 37px;
		font-size: 2.6em;
		line-height: 46px;
		padding: 0 0 17px;
		position: relative;
    @include breakpoint(xs) {
      border-color: transparent;
      display: block;
      text-align: center;
    }
  	}
}
// Home Page 
.carousel-inner {
	.item {
		overflow: hidden;
		max-height: 600px;
	}
  .carousel-caption {
    top: 0;
    right: 15%;
    left: auto;
    background: black;
    p {
      color: white;
      font-size: 24px;
    }
  }
}
.thumbnail {
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}
.home-intro {
	background-color: darken($brand-primary,22%);
  margin: 0 0 20px 0;
  overflow: hidden;
  padding: 15px 0;
  position: relative;
  text-align: left;
  .btn {
    background: $brand-green;
    border-color: $brand-green;
    &:hover {
      background: darken($brand-green,10%);
    }
  }
  @include breakpoint(xs) { 
  	text-align: center;
  	font-size: 12px;
  }
  a {
  	@include breakpoint(xs) { 
  		text-align: center;
  		margin: 0 auto;
  		float: none !important;
  	}
  }
  p {
  	color: #FFF;
		display: inline-block;
		font-size: 1.4em;
		font-weight: 300;
		max-width: 800px;
		padding: 0 0 0 0;
    margin: 0;
    line-height: 46px;
    @include breakpoint(xs) { 
      line-height: 24px;
      padding-bottom: 15px;
    }
  }
}
// Contact
 #map-canvas_2 {
        height:325px;
        width:100%;
        margin-bottom: 35px;
        background: url('../images/contact-map.jpg') no-repeat center center;
        background-size: cover;
    }
    .gm-style-iw * {
        display: block;
        width: 100%;
    }
    .gm-style-iw h4, .gm-style-iw p {
        margin: 0;
        padding: 0;
    }
    .gm-style-iw a {
        color: #4272db;
    }
.page-template-template-contact {
	.page-top {
		margin-bottom: 0;
	}
}
// Services
.featured-box {
	margin-bottom: 30px;
  	margin-top: 45px;
  	background: #F5F5F5;
  	border-bottom: 1px solid #DFDFDF;
  	border-left: 1px solid #ECECEC;
  	border-radius: 8px;
  	border-right: 1px solid #ECECEC;
  	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  	margin-bottom: 20px;
  	margin-left: auto;
  	margin-right: auto;
  	margin-top: 20px;
  	min-height: 100px;
  	position: relative;
  	text-align: center;
  	z-index: 1;
}
#services-blocks a:hover h4 {
  text-decoration: none;
}
.featured-box .box-content {
  border-radius: 8px;
  border-top: 3px solid #33B8FF;
  border-top-width: 4px;
  padding: 30px 20px;
  i {
    background-color: $brand-primary;
  	border-radius: 100%;
	color: #FFF;
	font-size: 40px;
	line-height: 110px;
	margin: 25px 0;
	padding: 25px;
	display: inline-block;
	padding: 0;
	width: 110px;
	height: 110px;
  }
  img {
    font-size: 40px;
  line-height: 110px;
  margin: 25px 0;
  padding: 25px;
  display: inline-block;
  padding: 0;
  width: 110px;
  height: 110px;
  }
  h4 {
  	  font-size: 1.3em;
  font-weight: 400;
  letter-spacing: -0.7px;
  margin-top: 5px;
  text-transform: uppercase;
  }
}