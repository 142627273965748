.arrow.hlt {
	background-position: -209px 0;
  	width: 120px;
  	height: 47px;
}
.arrow {
	background: transparent url(../../assets/images/arrows.png) no-repeat 0 0;
  	width: 47px;
  	height: 120px;
  	display: inline-block;
  	position: absolute;
  	top: 0;
  	right: 0;
}
.learn-more {
  display: block;
  margin-top: 15px;
  color: $brand-primary;
  font-weight: bold;
}