// Grid settings
// -------------------------

$grid-columns:          12;
$main-sm-columns:       $grid-columns;
$sidebar-sm-columns:    4;

// Brand colors
// -------------------------

$brand-primary:         #0093c4 !default;
$brand-green:			#93c647 !default;
$brand-yellow:			#fef250 !default;

$brand-success:         #fef250 !default;
$brand-info:            #96c359 !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

// Glyphicons path
// -------------------------

$icon-font-path:        "../../bower_components/bootstrap-sass-official/assets/fonts/bootstrap/";

// Typography
$font-family-sans-serif:  "Open Sans", Helvetica, Arial, sans-serif !default;
$text-color:            $gray-light !default;
$headings-color:          #0e0e0e !default;

// Padding 
$size_xs:				5px;
$size_sm:				15px;
$size_md:				25px;
$size_lg:				45px;

@mixin breakpoint($point) {
  @if $point == lg {
    @media (max-width: 1200px) { @content; }
  }
  @else if $point == md {
    @media (max-width: 992px) { @content; }
  }
   @else if $point == sm {
    @media (max-width: 768px) { @content; }
  }
  @else if $point == xs {
    @media (max-width: 480px)  { @content; }
  }
}