.zipcode-form {
	max-width: 350px;
	display: block;
	margin: 30px auto 5px auto;
	input {
		padding: 25px;
		font-size: 24px;
	}
	button {
		padding: 15px 25px;
	}
	.input-group {
		position: relative;
	}
	.arrow {
		z-index: 100;
		right: -150px;
		@extend .hidden-xs;
	}
}
.contact-page-form {
	label {
		font-weight: 300;
	}
	label.gfield_label {
		text-transform: uppercase;
		font-weight: 300;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	li {
		padding-bottom: 15px;
	}
	input,
	textarea {
		border-radius: 5px;
		border: 2px solid #0092c6;
		margin-top: 0;
		padding: 5px !important;
		background: #ebf3dc;
		border-radius: 0;
		&:focus {
			outline: none;
		}
	}
	textarea {
		width: 100%;
		max-width: 100%;
	}
	input[type=submit] {
		padding: 5px 15px !important;
		@extend .btn;
		@extend .btn-primary;
		@extend .btn-lg;
		@extend .pull-right;
	}
}